.factionHeader {
  color: var(--primary-color);
  cursor: pointer;
  background-color: inherit;
  border: none;
  outline: none;
}

.arrow {
  border: solid var(--primary-color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow.up {
  transform: translate(0.5rem, 0) rotate(-135deg);
}

.arrow.down {
  transform: translate(0.5rem, -0.2rem) rotate(45deg);
}

.extractsList {
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.extractsList.hidden {
  height: 0;
}
