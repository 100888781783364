.pickerButton {
  composes: button from "../Button/Button.module.css";

  padding: 0.3rem;
  border: none;
  background-color: transparent;
  opacity: 0.6;
  text-transform: uppercase;
}

.pickerButton:hover {
  background-color: transparent;
}

.pickerButton.selected {
  opacity: 1;
  border-bottom: 1px solid #9a8866;
}
