.button {
  outline: none;
  color: var(--primary-color);
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.button:hover {
  background-color: #303030;
}

.button:active {
  background-color: #404040;
}
