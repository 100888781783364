.container {
  margin-bottom: 1em;
}

.groupHeader {
  color: var(--primary-color);
}

.map {
  padding: 0.3em 1em;
  cursor: pointer;
  border-radius: 6px;
}

.map:hover {
  background-color: var(--list-item-hovered);
}

.map.selected {
  background-color: var(--list-item-selected);
}
