.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 30rem;
  max-width: 100%;
  bottom: 0;
  z-index: 9999;
  transition: right 0.1s;
  box-shadow:
    0 0 100px rgba(65, 61, 52, 1),
    0 0 6px rgba(232, 190, 107, 0.8);
}

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 1rem;
  background-color: inherit;
  max-height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.sidebar.hidden {
  right: -35rem;
}

.sidebarContent {
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  min-height: 3em;
}

.header .buttons {
  display: flex;
  gap: 1rem;
}
